import React, { useCallback } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Select, Input } from '@rebass/forms'
import { SearchButton } from '../search'
import { Grid, GridItem, SrOnly } from '../ui'

const EventsFilter = ({ values = {}, onSubmit, ...props }) => {
  const { wp } = useStaticQuery(graphql`
    {
      wp {
        eventCategories(
          where: {
            language: DEFAULT
          }
        ) {
          nodes {
            slug
            name
          }
        }

        distinctTowns {
          town
        }
      }
    }
  `)

  const handleSubmit = useCallback((e) => {
    e.preventDefault()

    const formData = new FormData(e.target)
    const newValues = {
      town: formData.get('town'),
      search: formData.get('search'),
      fromDate: formData.get('fromDate'),
      categorySlugs: formData.get('categorySlugs'),
    }

    onSubmit(newValues)
  }, [])

  const towns = wp.distinctTowns
  const categories = wp.eventCategories.nodes

  return (
    <Grid
      {...props}
      mx={-1}
      as="form"
      onSubmit={handleSubmit}
    >
      <GridItem
        p={1}
        width={[1, 1 / 2, 1 / 4]}
      >
        <SrOnly
          as="label"
          htmlFor="search-term"
        >
          Keresés
        </SrOnly>
        <Input
          type="text"
          name="search"
          id="search-term"
          placeholder="Keresés"
          value={values.search || ''}
        />
      </GridItem>
      <GridItem
        p={1}
        width={[1, 1 / 2, 1 / 4]}
      >
        <SrOnly
          as="label"
          htmlFor="fromDate"
        >
          Dátum
        </SrOnly>
        <Input
          type="date"
          id="fromDate"
          name="fromDate"
          placeholder="Dátum"
          value={values.fromDate || ''}
        />
      </GridItem>
      <GridItem
        p={1}
        width={[1, 1 / 2, 1 / 5]}
      >
        <SrOnly
          as="label"
          htmlFor="categorySlugs"
        >
          Kategória
        </SrOnly>
        <Select
          id="categorySlugs"
          name="categorySlugs"
          value={values.categorySlugs || ''}
        >
          <option value="">Kategória</option>
          {categories.map(({ slug, name }) => (
            <option
              key={slug}
              value={slug}
            >
              {name}
            </option>
          ))}
        </Select>
      </GridItem>
      <GridItem
        p={1}
        width={[1, 1 / 2, 1 / 5]}
      >
        <SrOnly
          as="label"
          htmlFor="town"
        >
          Település
        </SrOnly>
        <Select
          id="town"
          name="town"
          value={values.town || ''}
        >
          <option value="">Település</option>
          {towns.map(({ town }) => (
            <option
              key={town}
              value={town}
            >
              {town}
            </option>
          ))}
        </Select>
      </GridItem>
      <GridItem
        p={1}
        width={[1, 1, 'auto']}
        flex={['initial', 'initial', 1]}
        textAlign={['initial', 'right']}
      >
        <SearchButton />
      </GridItem>
    </Grid>
  )
}

export default EventsFilter
