import React, { useCallback, useEffect } from 'react'
import qs from 'query-string'
import { graphql } from 'gatsby'
// eslint-disable-next-line
import { useLocation } from '@reach/router'
import { useManualQuery } from 'graphql-hooks'
import client from '../graphqlClient'
import { Box, Button } from '../components/ui'
import { PageLayoutEvents } from '../components/layout'
import {
  EventList, EventsSearchResults, EventsFilter, EVENTS_SEARCH_QUERY,
} from '../components/events'
import {
  MetaTagsMustHave, createPaginatedPageTitle, createPaginatedMetaProps,
} from '../components/seo'
import { Pagination, PaginationPaged } from '../components/content'

const Events = ({ data, pageContext, navigate }) => {
  const { themeOptions, futureEvents } = data.wp
  const [requestSearchEvents, searchState] = useManualQuery(EVENTS_SEARCH_QUERY, { client })

  const location = useLocation()
  const searchVariables = qs.parse(location.search)
  const isSearchActive = Object.keys(searchVariables).length > 0

  const handleSubmitSearch = useCallback((newSearchValues) => {
    navigate(`${location.pathname}?${qs.stringify(newSearchValues)}`, {
      replace: true,
    })
  }, [location.pathname])

  const handleResetSearch = useCallback(() => {
    navigate(location.pathname, { replace: true })
  }, [location.pathname])

  useEffect(() => {
    if (isSearchActive) {
      const perPage = 25
      const variables = (qs.parse(location.search))

      requestSearchEvents({
        variables: {
          ...variables,
          first: perPage,
          offset: Math.max(0, +variables.page - 1) * perPage,
          scope: variables.fromDate ? null : 'FUTURE',
        },
      })
    }
  }, [isSearchActive, location.search])

  const staticlyRenderedNodes = futureEvents.nodes.length > 0
    ? (
      <>
        <EventList events={futureEvents.nodes} />
        <Pagination {...pageContext} />
      </>
    ) : (
      <Box>
        Nincsenek programok.
      </Box>
    )

  return (
    <>
      <MetaTagsMustHave
        {...createPaginatedMetaProps(
          pageContext.humanPageNumber,
          themeOptions.page.seo,
        )}
      />
      <PageLayoutEvents
        heading={createPaginatedPageTitle(
          pageContext.humanPageNumber,
          themeOptions.page.title,
        )}
      >
        {
          futureEvents.nodes.length > 0 && (
            <EventsFilter
              mb={3}
              values={searchVariables}
              onSubmit={handleSubmitSearch}
            />
          )
        }
        {
          isSearchActive
            ? (
              <>
                <Button
                  mb={3}
                  mx="auto"
                  variant="text"
                  onClick={handleResetSearch}
                >
                  Szűrők törlése
                </Button>
                <EventsSearchResults
                  {...searchState}
                  events={searchState?.data?.events?.nodes}
                />
                <PaginationPaged
                  pathPrefix={location.pathname}
                  searchVariables={searchVariables}
                  humanPageNumber={+searchVariables.page || 1}
                  hasNextPage={(
                    searchState?.data?.events?.pageInfo?.offsetPagination?.hasMore ?? false
                  )}
                />
              </>
            ) : staticlyRenderedNodes
        }
      </PageLayoutEvents>
    </>
  )
}

export default Events

export const pageQuery = graphql`
  query EventsQuery($first: Int, $after: String) {
    wp {
      themeOptions {
        page: eventsPage {
          title
          seo {
            ...SeoFields
          }
        }
      }
      futureEvents: events(
        first: $first
        after: $after
        where: {
          scope: FUTURE
          status: PUBLISH
          language: DEFAULT
          orderby: {
            order: ASC
            field: START_DATE
          }
        }
      ) {
        nodes {
          ...EventListFields
          ...EventListImageFields
        }
      }
    }
  }
`
